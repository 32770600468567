import "./Calendar.css";
import CommonLayout from "components/layout/Layout";
import React, { useEffect, useState, useRef } from "react";
import { Radio, Dropdown, Button, Space, Alert, Spin } from "antd";
import {
  LeftOutlined,
  RightOutlined,
  PlusOutlined,
  CaretDownOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import CreateAdset from "./CreateAdset";
import CreateEvent from "./CreateEvent";
import { useDispatch, useSelector } from "react-redux";
import { getAllAdsets, getAllEvents } from "actions/calendar";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from "@fullcalendar/multimonth";
import { setPage } from "actions/app";
import { TextMessage, SECONDS } from "utils/constants";
import session from "utils/session";

const CalendarManagement = () => {
  const dispatch = useDispatch();
  const [size, setSize] = useState("monthly");
  const [showCreate, setShowCreate] = useState(false);
  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const { adsets, events } = useSelector((state) => state.calendar);
  const calendarRef = useRef(null);
  const [currentViewTitle, setCurrentViewTitle] = useState("");
  const [selectedEventData, setSelectedEventData] = useState();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showDeleteEventAlert, setShowDeleteEventAlert] = useState(false);
  const [showCreateEventAlert, setShowCreateEventAlert] = useState(false);
  const [showCreateAdsetAlert, setShowCreateAdsetAlert] = useState(false);
  const [showUpdateEventAlert, setShowUpdateEventAlert] = useState(false);
  const [showUpdateAdsetAlert, setShowUpdateAdsetAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlart] = useState(false);
  const [canEditable, setCanEditable] = useState(true);
  const [errorAlertText, setErrorAlertText] = useState("");
  const [initialDate, setInitialDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const role = session && session.role ? session.role.toLowerCase() : "";

  const handlePrevClick = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().prev();
      getFormattedDate(size);
    }
  };

  const handleNextClick = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().next();
      getFormattedDate(size);
    }
  };

  const getCall = async () => {
    setLoadingState(true);
    const res = await dispatch(getAllAdsets({ sortBy: 'startDate', orderBy: 'ASC' }));
    await dispatch(getAllEvents({ perPage: 1000, sortBy: 'startDate', orderBy: 'ASC' }));
    if (res) {
      setLoadingState(false);
    }
  };

  useEffect(() => {
    getCall();
  }, [dispatch]);

  useEffect(() => {
    getFormattedDate(size);
    dispatch(setPage("Calendar Management"));
  }, []);

  const getFormattedDate = (size, event) => {
    if (!event && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      const currentDate = calendarApi.getDate();
      const formattedDate = moment(currentDate).format(size === "monthly" ? "MMM YYYY" : "YYYY");
      setCurrentViewTitle(formattedDate);
    } else {
      const currentDate = new Date(event.startDate);
      const formattedDate = moment(currentDate).format(size === "monthly" ? "MMM YYYY" : "YYYY");
      setCurrentViewTitle(formattedDate);
    }
  };

  const handleMenuClick = (e) => {
    setSelectedEventData(undefined);
    if (e.key === "1") {
      setShowCreate(true);
    }
    if (e.key === "2") {
      setShowCreateEvent(true);
    }
  };

  const items = [
    { label: "Adset", key: "1" },
    { label: "Event", key: "2" },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const handleCreateClose = (params, data) => {
    // getCall();
    if (params === "Delete") {
      getCall();
      setShowDeleteAlert(true);
    }
    if (params === "Create") {
      getCall();
      setShowCreateAdsetAlert(true);
      getFormattedDate("monthly", data);
      const date = moment(data.startDate).format("YYYY-MM-DD");
      setInitialDate(date);
    }
    if (params === "Update") {
      getCall();
      setShowUpdateAdsetAlert(true);
      getFormattedDate("monthly", data);
      const date = moment(data.startDate).format("YYYY-MM-DD");
      setInitialDate(date);
    }
    setShowCreate(false);
  };

  const handleCreateEventClose = (params, data) => {
    // getCall();
    if (params === "Delete") {
      getCall();
      setShowDeleteEventAlert(true);
    } else if (params === "Create") {
      getCall();
      setShowCreateEventAlert(true);
      getFormattedDate("monthly", data);
      const date = moment(data.startDate).format("YYYY-MM-DD");
      setInitialDate(date);
    } else if (params === "Update") {
      getCall();
      setShowUpdateEventAlert(true);
      getFormattedDate("monthly", data);
      const date = moment(data.startDate).format("YYYY-MM-DD");
      setInitialDate(date);
    } else if (params === "Error") {
      getCall();
      setShowErrorAlart(true);
      setErrorAlertText(data?.message);
    }
    setShowCreateEvent(false);
  };

  const handleMonthViewClick = (e) => {
    setSize(e.target.value);
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      if (e.target.value === "monthly") {
        calendarApi.changeView("dayGridMonth");
      } else {
        calendarApi.changeView("multiMonthYear");
      }
      getFormattedDate(e.target.value);
    }
  };

  const handleEventClick = (info) => {
    if (role?.toLowerCase() === "admin") {
      setCanEditable(true);
    } else {
      setCanEditable(false);
    }
    const elements = document.getElementsByClassName("fc-popover-close");
    if (info.event.classNames.includes("adset")) {
      setSelectedEventData(adsets.find((item) => item._id === info.event.id));
      setShowCreate(true);
    } else {
      setSelectedEventData(events.find((item) => item._id === info.event.id));
      setShowCreateEvent(true);
    }
    if (elements.length > 0) {
      elements[0].click();
    }
  };

  const handleAlertClose = () => {
    setShowDeleteAlert(false);
    setShowDeleteEventAlert(false);
    setShowCreateEvent(false);
    setShowCreateAdsetAlert(false);
    setShowUpdateAdsetAlert(false);
    setShowUpdateEventAlert(false);
    setShowErrorAlart(false);
  };

  const handleEdit = (event, type) => {
    if (role?.toLowerCase() === "admin") {
      setCanEditable(true);
    } else {
      setCanEditable(false);
    }
    if (type === "adset") {
      setSelectedEventData(adsets.find((item) => item._id === event._id));
      setShowCreate(true);
    } else {
      setSelectedEventData(events.find((item) => item._id === event._id));
      setShowCreateEvent(true);
    }
  };

  useEffect(() => {
    if (showDeleteAlert) {
      setTimeout(() => {
        setShowDeleteAlert(false);
      }, SECONDS);
    }
    if (showDeleteEventAlert) {
      setTimeout(() => {
        setShowDeleteEventAlert(false);
      }, SECONDS);
    }
    if (showCreateEventAlert) {
      setTimeout(() => {
        setShowCreateEventAlert(false);
      }, SECONDS);
    }
    if (showCreateAdsetAlert) {
      setTimeout(() => {
        setShowCreateAdsetAlert(false);
      }, SECONDS);
    }
    if (showUpdateAdsetAlert) {
      setTimeout(() => {
        setShowUpdateAdsetAlert(false);
      }, SECONDS);
    }
    if (showUpdateEventAlert) {
      setTimeout(() => {
        setShowUpdateEventAlert(false);
      }, SECONDS);
    }
    if (showErrorAlert) {
      setTimeout(() => {
        setShowErrorAlart(false);
      }, SECONDS);
    }
  }, [
    showDeleteAlert,
    showDeleteEventAlert,
    showCreateEventAlert,
    showCreateAdsetAlert,
    showUpdateAdsetAlert,
    showUpdateEventAlert,
    showErrorAlert
  ]);

  const convertUTCToLocal = (utcDateStr) => {
    if (utcDateStr) {
      const date = moment.utc(utcDateStr).local().format('MM/DD/YYYY');
      return date;
    } else {
      return null;
    }
  }

  const adsetsArray = adsets.map((adset) => ({
    ...adset,
    classNames: ["adset"],
    displayOrder: 1,
    startDate: convertUTCToLocal(adset.startDate),
    endDate: convertUTCToLocal(adset.endDate),
  }));

  const eventsArray = events.map((event) => ({
    ...event,
    classNames: ["event"],
    displayOrder: 2,
    startDate: convertUTCToLocal(event.startDate),
    endDate: convertUTCToLocal(event.endDate),
  }));

  const sortedCombinedEvent = [...adsetsArray, ...eventsArray];

  const renderEventContent = (eventInfo) => {
    const { classNames, title } = eventInfo.event;
    const isAdset = classNames.includes("adset");

    return (
      <div style={{ backgroundColor: isAdset ? '#eef0f4' : '#eef4ff' }}>
        {title}
      </div>
    );
  };

  return (
    <CommonLayout className="content-mr" breadCrumbText="Calendar Management">
      {loadingState ? (
        <div className="errorTipDiv">
          <Spin size="middle" tip="Loading..." />
        </div>
      ) : (
        <div className="box-container">
          <div className="left-box">
            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, multiMonthPlugin]}
              initialView={"dayGridMonth"}
              headerToolbar={{ start: "", center: "", end: "" }}
              height={"85vh"}
              events={sortedCombinedEvent}
              eventOrder="displayOrder"
              eventContent={renderEventContent}
              eventClick={handleEventClick}
              initialDate={initialDate}
            />
          </div>
          <div className="right-box">
            <div className="month-changer">
              <span>{currentViewTitle}</span>
              <div>
                <LeftOutlined className="left-icon" onClick={handlePrevClick} />
                <RightOutlined onClick={handleNextClick} />
              </div>
            </div>
            <div className="calendar-change">
              <Radio.Group value={size} onChange={(e) => handleMonthViewClick(e)}>
                <Radio.Button value="monthly" className={size === "monthly" ? "btnStyle" : "btnStyle1"}>
                  Monthly
                </Radio.Button>
                <Radio.Button value="yearly" className={size === "yearly" ? "btnStyle" : "btnStyle1"}>
                  Yearly
                </Radio.Button>
              </Radio.Group>
            </div>
            {role === "admin" && (
              <div>
                <Dropdown menu={menuProps} trigger={["click"]}>
                  <Button className="btnStyle w-152">
                    <Space>
                      <PlusOutlined />
                      Create
                      <CaretDownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </div>
            )}
            <div className="adset-wrapper">
              <span className="adset-wrapper-label">Adsets</span>
              <div className="adset-list">
                {adsets.map((value) => (
                  <div className="adset-name" onClick={() => handleEdit(value, "adset")}>
                    <div>{value.name}</div>
                    {size === "yearly" && (
                      <div className="date-range-label">
                        {moment(value?.startDate).format("MMM DD") + " - " + moment(value?.endDate).format("MMM DD")}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="adset-wrapper">
              <span className="adset-wrapper-label">Events</span>
              <div className="adset-list">
                {events.map((value) => (
                  <div className="event-name" onClick={() => handleEdit(value, "event")}>
                    <div>{value.name}</div>
                    {size === "yearly" && (
                      <div className="date-range-label">
                        {moment(value?.startDate).format("MMM DD") + " - " + moment(value?.endDate).format("MMM DD")}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {showCreate && (
        <CreateAdset visible={showCreate} onClose={handleCreateClose} eventData={selectedEventData} editable={!canEditable} />
      )}

      {showCreateEvent && (
        <CreateEvent visible={showCreateEvent} onClose={handleCreateEventClose} eventData={selectedEventData} editable={!canEditable} />
      )}
      {showDeleteAlert && (
        <Alert
          message={TextMessage.ADSET_DELETE_TEXT}
          type="error"
          showIcon
          closable
          onClose={handleAlertClose}
          icon={<DeleteOutlined />}
          className="alertStyle"
        />
      )}
      {showDeleteEventAlert && (
        <Alert
          message={TextMessage.EVENT_DELETE_TEXT}
          type="error"
          showIcon
          closable
          onClose={handleAlertClose}
          icon={<DeleteOutlined />}
          className="alertStyle"
        />
      )}
      {showCreateEventAlert && (
        <Alert
          message={TextMessage.EVENT_CREATE_TEXT}
          type={"success"}
          showIcon
          closable
          onClose={handleAlertClose}
          className="alertStyle"
        />
      )}
      {showErrorAlert && (
        <Alert
          message={errorAlertText}
          type={"error"}
          showIcon
          closable
          onClose={handleAlertClose}
          className="alertStyle"
        />
      )}
      {showCreateAdsetAlert && (
        <Alert
          message={TextMessage.ADSET_CREATE_TEXT}
          type={"success"}
          showIcon
          closable
          onClose={handleAlertClose}
          className="alertStyle"
        />
      )}
      {showUpdateEventAlert && (
        <Alert
          message={TextMessage.EVENT_UPDATE_TEXT}
          type={"success"}
          showIcon
          closable
          onClose={handleAlertClose}
          className="alertStyle"
        />
      )}
      {showUpdateAdsetAlert && (
        <Alert
          message={TextMessage.ADSET_UPDATE_TEXT}
          type={"success"}
          showIcon
          closable
          onClose={handleAlertClose}
          className="alertStyle"
        />
      )}
    </CommonLayout>
  );
};

export default CalendarManagement;
